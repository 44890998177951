// Check each input field against a regex and return boolean
export const validateField = (field, value) => {
  value = value.trim(); // Remove any trailing blank spaces
  switch (field) {
    case "fullName":
      return value.match(/^(?=.{2,30}$)[a-z]+(?:['_.\s][a-z]+)*$/i)
        ? true
        : false;
    case "firstName":
      return value.match(/^(?=.{2,20}$)[a-z]+(?:['_.\s][a-z]+)*$/i)
        ? true
        : false;
    case "lastName":
      return value.match(/^(?=.{2,20}$)[a-z]+(?:['_.\s][a-z]+)*$/i)
        ? true
        : false;
    case "email":
      return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
    case "phone":
      return value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
        ? true
        : false;
    default:
      return value.length > 0;
  }
};
