import styled from "styled-components";

export const Input = styled.input`
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #eaeaea;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 8px 12px;
  width: 100%;

  &::-ms-clear {
    display: none;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  &.invalid {
    background-color: #ffe6e6;
    border-color: red;
  }
`;

export const TextArea = styled.textarea`
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #eaeaea;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  font: inherit;
  margin: 0;
  overflow: auto;
  padding: 8px 12px;
  width: 100%;

  &.invalid {
    background-color: #ffe6e6;
    border-color: red;
  }

  &::-ms-clear {
    display: none;
  }
`;
