import React from "react";
import styled from "styled-components";

const BlurbWrapper = styled.div`
  position: relative;
  z-index: 1;
  div {
    background-color: #f4f5f7;
    border-radius: 8px;
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    font-size: 12px;
    padding: 8px 20px;
    position: absolute;
    top: -8px;
    &:before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 6px solid #f4f5f7;
      content: "";
      height: 0;
      position: absolute;
      top: -6px;
      width: 0;
    }
    &.error {
      background-color: #ffe6e6;
      color: #ff0504;
      &:before {
        border-bottom-color: #ffe6e6;
      }
    }
    &.warning {
      background-color: #fff2c9;
      color: #d09d00;
      &:before {
        border-bottom-color: #fff2c9;
      }
    }
    &.right {
      right: 0;
      &:before {
        right: 20px;
      }
    }
  }
`;

const Blurb = ({ message, type, position }) => (
  <BlurbWrapper>
    <div className={type + " " + position}>{message}</div>
  </BlurbWrapper>
);

Blurb.defaultProps = {
  message: `This field is required.`,
};

export default Blurb;
