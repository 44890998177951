import React from "react";
import styled from "styled-components";
import { FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import IconInstagram from "../images/icon-instagram.svg";

const SocialLinksWrapper = styled.div`
  font-size: 30px;
  a {
    color: #081a31;
    margin: 0 12px;
  }
  svg {
    vertical-align: top;
  }
  .instagram {
    margin: 2px;
    width: 26px;
    vertical-align: top;
  }
`;

const SocialLinks = () => (
  <SocialLinksWrapper>
    <a
      href="https://www.facebook.com/pages/Travel-Nursingorg/633382013356067"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaFacebookSquare />
    </a>
    <a
      href="https://twitter.com/travelnursing14"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaTwitterSquare />
    </a>
    <a
      href="https://www.instagram.com/travel_nursing"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={IconInstagram} alt="Instagram" className="instagram" />
    </a>
  </SocialLinksWrapper>
);

SocialLinks.SocialLinksWrapper = SocialLinksWrapper;

export default SocialLinks;
