import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React, { Component } from "react";
import { FaHeart } from "react-icons/fa";
import styled from "styled-components";
import Blurb from "../components/Blurb";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import SocialLinks from "../components/SocialLinks";
import { Input, TextArea } from "../ui/Input";
import { validateField } from "../utils/validateField";
import theme from "../ui/theme";

const StyledBackgroundImage = styled(BackgroundImage)`
  margin-bottom: 60px;
  padding: 80px 10px;
  text-align: center;

  @media all and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 30px;
    padding: 30px 10px;
  }

  h1 {
    font-size: 45px;
    margin-bottom: 10px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 20px;
  }

  svg {
    fill: #1b7dff;
    font-size: 24px;
    vertical-align: bottom;
  }
`;

const ContactContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 640px;
  padding: 0 20px;

  @media all and (min-width: 960px) {
    min-height: 400px;
  }

  p {
    font-size: 20px;
    margin-bottom: 40px;

    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  ${SocialLinks.SocialLinksWrapper} {
    margin-bottom: 40px;

    @media all and (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 400;
  }

  form {
    margin: 0 auto;
    text-align: left;
    max-width: 480px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  [type="text"],
  [type="email"] {
    margin-bottom: 15px;
  }

  textarea {
    margin-bottom: 15px;
    resize: vertical;
  }

  button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 40px;
  }
`;

export default class Contact extends Component {
  state = {
    fullName: {
      value: "",
      valid: false,
    },
    email: {
      value: "",
      valid: false,
    },
    message: {
      value: "",
      valid: false,
    },
    userSubmitted: false,
    isSubmittingForm: false,
    submissionComplete: false,
  };

  // Update the current field state and validate
  handleChange = (event, field) => {
    this.setState({
      [field]: {
        valid: validateField(field, event.target.value),
        value: event.target.value.trim(),
      },
    });
  };

  // Encode the form body contents
  encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
      )
      .join("&");
  };

  // Make sure the fields are valid, then submit form to Netlify
  handleSubmit = (evt) => {
    const { fullName, email, message } = this.state;
    this.setState({ userSubmitted: true });
    if (fullName.valid && email.valid && message.valid) {
      this.setState({ isSubmittingForm: true });
      fetch("/contact", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": "Contact us",
          Name: fullName.value,
          Email: email.value,
          Comments: message.value,
        }),
      })
        .then(() => {
          this.setState({ submissionComplete: true });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isSubmittingForm: false });
        });
    } else {
      this.setState({ isSubmittingForm: false });
    }
    evt.preventDefault();
  };

  render() {
    const data = this.props.data;
    return (
      <Layout templateName="contact wide">
        <Seo
          title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
          description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
          canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
        />
        <StyledBackgroundImage fluid={data.mapImage.childImageSharp.fluid}>
          <h1>{data.wordpressPage.title}.</h1>
          <h3>
            We'd <FaHeart /> to help.
          </h3>
        </StyledBackgroundImage>
        <ContactContainer>
          {!this.state.submissionComplete ? (
            <>
              <p>
                Call us at <a href="tel:+1-888-971-2844">888-971-2844</a>, fill
                out the form below, or connect with us via social media.
              </p>
              <SocialLinks />
              <form
                name="Contact us"
                data-netlify="true"
                action="/success-page"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="Contact us" />
                <h3>Name</h3>
                <Input
                  type="text"
                  placeholder="First and Last Name"
                  name="Name"
                  onChange={(e) => this.handleChange(e, "fullName")}
                  className={
                    !this.state.fullName.valid &&
                    this.state.userSubmitted &&
                    "invalid"
                  }
                />
                {!this.state.fullName.valid && this.state.userSubmitted && (
                  <Blurb
                    type="error"
                    message="Please enter your name."
                    position="right"
                  />
                )}
                <h3>E-mail</h3>
                <Input
                  type="email"
                  placeholder="email@company.com"
                  name="Email"
                  onChange={(e) => this.handleChange(e, "email")}
                  className={
                    !this.state.email.valid &&
                    this.state.userSubmitted &&
                    "invalid"
                  }
                />
                {!this.state.email.valid && this.state.userSubmitted && (
                  <Blurb
                    type="error"
                    message="Must be a valid email."
                    position="right"
                  />
                )}
                <h3>Message</h3>
                <TextArea
                  placeholder="How can we help?"
                  rows="8"
                  name="Comments"
                  onChange={(e) => this.handleChange(e, "message")}
                  className={
                    !this.state.message.valid &&
                    this.state.userSubmitted &&
                    "invalid"
                  }
                />
                {!this.state.message.valid && this.state.userSubmitted && (
                  <Blurb type="error" position="right" />
                )}
                <button className="btn btn--secondary" type="submit">
                  {this.state.isSubmittingForm ? "Sending..." : "Send"}
                </button>
              </form>
            </>
          ) : (
            <>
              <h2>Thank you for contacting us.</h2>
              <p>
                One of our staff members will respond as quickly as possible.
              </p>
              <Link
                to="/frequently-asked-questions/"
                className="btn btn--secondary"
              >
                View Travel Nursing FAQ
              </Link>
            </>
          )}
        </ContactContainer>
      </Layout>
    );
  }
}

export const query = graphql`
  query ContactQuery {
    mapImage: file(relativePath: { eq: "bg-map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 23 }) {
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;
